/* Framework imports -------------------------------------------------------- */
import React from 'react'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import { useNavigate } from 'react-router-dom'
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import { useResetPasswordMutation } from 'store/api'
import { isApiError } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import { Button } from '@mui/material'
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import { toast } from 'react-toastify'
import AuthContainer from 'components/AuthComponents/AuthContainer'
import Loader from 'components/Loader/Loader'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import AuthWelcomeMessage from 'components/AuthComponents/AuthWelcomeMessage'
import AuthInputContainer from 'components/AuthComponents/AuthInputContainer'
import AuthButtonsContainer from 'components/AuthComponents/AuthButtonsContainer'

/* Type imports ------------------------------------------------------------- */
import type { FormikHelpers } from 'formik'
import type { ResetPasswordValidator } from 'API/__generated__/Api'

/* Types declaration -------------------------------------------------------- */
const NewPasswordFormSchema = Yup.object().shape({
  email: Yup.string().required("L'email est obligatoire"),
})

/* Component declaration ---------------------------------------------------- */
interface ResetPasswordPageProps {}

const ResetPasswordPage: React.FC<ResetPasswordPageProps> = () => {
  const navigate = useNavigate()

  const [
    resetPassword,
  ] = useResetPasswordMutation()

  const onSubmit = (values: ResetPasswordValidator, { setSubmitting }: FormikHelpers<ResetPasswordValidator> ): void | Promise<void> => {
    resetPassword(values).then((response) => {
      setSubmitting(false)
      if (isApiError(response)) {
        toast.error(`Une erreur est survenue.`)
      } else {
        toast.success(`Un email à été envoyé à votre adresse mail.`)
        navigate('/connexion')
      }
    }).catch(console.error)
  }

  const formikForm = useForm<ResetPasswordValidator>(
    {
      initialValues: {
        email: '',
      },
      onSubmit: onSubmit,
      validationSchema: NewPasswordFormSchema,
    },
  )

  const onCancelClick = () => {
    navigate('/connexion')
  }

  return (
    <AuthContainer>
      <Form form={formikForm}>
        {formikForm.isSubmitting && <Loader />}
        <AuthWelcomeMessage>
          Mot de passe oublié ?
        </AuthWelcomeMessage>
        <div>
          <AuthInputContainer>
            <FormBoldTitle>
              Email
            </FormBoldTitle>
            <Field
              component={TextField}
              placeholder="Email"
              name="email"
              autoComplete="username"
            />
          </AuthInputContainer>
        </div>
        <AuthButtonsContainer>
          <Button
            type="submit"
            disabled={formikForm.isSubmitting}
            variant="contained"
            fullWidth
          >
            Réinitialiser mon mot de passe
          </Button>
          <Button
            variant="outlined"
            fullWidth
            disabled={formikForm.isSubmitting}
            onClick={onCancelClick}
          >
            Annuler
          </Button>
        </AuthButtonsContainer>
      </Form>
    </AuthContainer>
  )
}

export default ResetPasswordPage
