/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useAuthInfo } from 'store/hooks'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Card,
} from '@mui/material'

/* Type imports ------------------------------------------------------------- */
import type { User } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const CardContainer = styled(Card)`
  margin: 10px 0px;
`

const CardContentContainer = styled.div`
  padding: 10px;
`

const ButtonContainer = styled.div`
  margin-top: 5px;
`

/* Component declaration ---------------------------------------------------- */
interface UsersTableMobileCardProps {
  user: User;
  onEdit: () => void;
}

const UsersTableMobileCard: React.FC<UsersTableMobileCardProps> = ({
  user,
  onEdit,
}) => {
  const authInfo = useAuthInfo()

  return (
    <CardContainer>
      <CardContentContainer>
        {`Id : ${user?.id || ''}`}
        <br />
        {`Prénom : ${user?.firstName || ''}`}
        <br />
        {`Nom : ${user?.lastName || ''}`}
        <br />
        {`Email : ${user.email || ''}`}
        <br />
        {`Statut : ${user?.isDisabled ? 'Inactif' : 'Actif'}`}
        <br />
        {
          authInfo.isAdmin &&
            <ButtonContainer>
              <Button
                variant="contained"
                onClick={onEdit}
                fullWidth
              >
                Modifier
              </Button>
            </ButtonContainer>
        }
      </CardContentContainer>
    </CardContainer>
  )
}

export default UsersTableMobileCard
