/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import { Menu } from '@mui/material'
import {
  Close,
  FilterAlt,
  FilterAltOutlined,
} from '@mui/icons-material'

/* Type imports ------------------------------------------------------------- */
import type { ColumnHeader } from 'components/Table/Table'

/* Styled components -------------------------------------------------------- */
const Container = styled.div`
  min-width: min(50svw, 300px);
  padding: 0px 10px;
  max-height: 400px;

  @media ${(props) => props.theme.media.mobile.main} {
    max-height: 100%;
  }
`

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
`

/* Component declaration ---------------------------------------------------- */
interface TableFilterProps {
  col: ColumnHeader;
  removeFilter?: () => void;
}

const TableFilter: React.FC<TableFilterProps> = ({ col, removeFilter }) => {
  const [ anchorMenu, setAnchorMenu ] = useState<null | HTMLDivElement>(null)

  if (!col.filterComponent) return null

  return (
    <FilterContainer onClick={(e) => {e.stopPropagation(); setAnchorMenu(e.currentTarget)}}>
      {
        col.isFiltered ?
          <>
            <FilterAlt />
            <Close onClick={(e) => {e.stopPropagation(); removeFilter && removeFilter()}} />
          </> :
          <FilterAltOutlined />
      }
      <Menu
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={() => setAnchorMenu(null)}
        onClick={(e): void => e.stopPropagation()}
      >
        <Container>
          {col.filterComponent}
        </Container>
      </Menu>
    </FilterContainer>
  )
}

export default TableFilter
