/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import { useNavigate } from 'react-router-dom'
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import Table, { exportToXLSX } from 'components/Table/Table'
import {
  useGetClientGroupListQuery,
  useGetClientPaginatedQuery,
} from 'store/api'
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks'
import {
  getClientsForm,
  setClientsForm,
} from 'store/slices/clientsFormSlice'

/* Component imports -------------------------------------------------------- */
import {
  Card,
  Collapse,
  Pagination,
} from '@mui/material'
import { KeyboardArrowDownRounded } from '@mui/icons-material'
import LargeTitle from 'components/LargeTitle/LargeTitle'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import LongButton from 'components/LongButton/LongButton'
import LoadingOverlay from 'components/Loader/LoadingOverlay'
import ClientsFilters from './ClientsComponents/ClientsFilters'
import ClientsTableMobileCard from './ClientsComponents/ClientsTableMobileCard'
import ClientsDeleteModal from './ClientsComponents/ClientsDeleteModal'

/* Type imports ------------------------------------------------------------- */
import type { FormikContextType } from 'formik'
import type {
  ColumnHeader,
  DataName,
  Order,
} from 'components/Table/Table'
import type {
  Client,
  IndexPaginatedClientListParams,
} from 'API/__generated__/Api'

/* Types declaration -------------------------------------------------------- */
const clientsSchema = Yup.object({
  name: Yup.string(),
  clientId: Yup.string(),
  groupIds: Yup.array(Yup.number().required()).nullable(),
  orderBy: Yup.mixed<DataName>().required(),
  order: Yup.mixed<Order>().required(),
  page: Yup.number().required(),
  limit: Yup.number().required(),
}).required()

export type ClientSchema = Yup.InferType<typeof clientsSchema>

type ClientForm = FormikContextType<ClientSchema>

/* Internal Variables ------------------------------------------------------- */
export const initialValues: ClientSchema = {
  name: '',
  clientId: '',
  groupIds: [],
  order: 'asc',
  orderBy: 'name',
  page: 1,
  limit: 25,
}

/* Styled components -------------------------------------------------------- */
const FilterDesktopContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;

  @media ${(props) => props.theme.media.mobile.main} {
    display: none;
  }

  @media ${(props) => props.theme.media.tablet} {
    grid-template-columns: 1fr;
    gap: 10px;
  }
`

const FilterMobileContainer = styled.div`
  display: none;

  @media ${(props) => props.theme.media.mobile.main} {
    display: initial;
  }
`

const FormTitleWithArrow = styled(FormBoldTitle)`
  align-items: center;
  cursor: pointer;
  margin-bottom: 0px;
  margin-top: 10px;
`

interface DropDownArrowProps {
  open: boolean;
}

const DropDownArrow = styled(KeyboardArrowDownRounded)<DropDownArrowProps>`
  transform: scaleY(${(props) => props.open ? '-1' : '1'});
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 36px;
`

const BoldSeparator = styled.div`
  margin-top: 10px;
  border-bottom: 2px solid ${(props) => props.theme.colors.grey};
`

const BoldSeparatorMargin = styled(BoldSeparator)`
  margin-bottom: 10px;
`

const TableCardContainer = styled(Card)`
  margin-top: 20px;

  @media ${(props) => props.theme.media.mobile.main} {
    display: none;
  }
`

const TableCardContentContainer = styled.div`
  padding: 0px 5px 5px;
`

const MobileCardContainer = styled.div`
  display: none;

  @media ${(props) => props.theme.media.mobile.main} {
    display: initial;
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;

  @media ${(props) => props.theme.media.mobile.portrait} {
    flex-direction: column;
  }
`

/* Component declaration ---------------------------------------------------- */
interface ClientsPageProps {}

const ClientsPage: React.FC<ClientsPageProps> = () => {
  const clientsForm = useAppSelector(getClientsForm)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [ openedFilterMenu, setOpenedFilterMenu ] = useState<boolean>(false)
  const [ totalRows, setTotalRows ] = useState<number | undefined>()
  const [ deleteModal, setDeleteModal ] = useState<Client | null>()

  const {
    currentData: groupList = [],
    isFetching: isFetchingGroupList,
  } = useGetClientGroupListQuery()

  const formikForm: ClientForm = useForm<ClientSchema>(
    {
      initialValues: clientsForm ?? initialValues,
      validationSchema: clientsSchema,
    },
  )

  useEffect(() => {
    dispatch(setClientsForm(formikForm.values))
  }, [ formikForm.values ])

  const {
    currentData: clientPagination,
    isFetching: isFetchingClientPagination,
  } = useGetClientPaginatedQuery(formikForm.values as unknown as IndexPaginatedClientListParams)

  useEffect(() => {
    const timer = setTimeout(() => {
      if (clientPagination?.meta?.total !== undefined && clientPagination?.meta?.total !== totalRows) {
        setTotalRows(clientPagination?.meta?.total)
      }
    }, 500)
    return () => clearTimeout(timer)
  }, [ isFetchingClientPagination ])

  const setOrder = (order: Order) => formikForm.setFieldValue('order', order)
  const setOrderBy = (orderBy: DataName) => formikForm.setFieldValue('orderBy', orderBy)
  const setLimit = (limit: number) => formikForm.setFieldValue('limit', limit)
  const setPage = (startIndex: number) => formikForm.setFieldValue('page', startIndex)

  const cols: ColumnHeader<Client>[] = [
    {
      id: 'id',
      label: 'Id',
    },
    {
      id: 'name',
      label: 'Nom',
    },
    {
      id: 'group.name',
      label: 'Groupe',
      sortId: 'groupId',
    },
    {
      id: 'phone',
      label: 'Téléphone',
    },
    {
      id: 'email',
      label: 'Email',
    },
    {
      id: 'comment',
      label: 'Description',
    },
  ]

  const exportDataToXLSX = () => {
    clientPagination?.data && exportToXLSX(cols.filter((col) => !col.removeFromExport), clientPagination.data, 'Clients')
  }

  const onEditClient = (id: string) => {
    navigate(`${id}`)
  }

  return (
    <Form form={formikForm}>
      <LargeTitle>
        Clients
        <ButtonsContainer>
          <LongButton
            variant="outlined"
            onClick={() => exportDataToXLSX()}
          >
            Exporter
          </LongButton>
          <LongButton
            variant="contained"
            onClick={() => navigate('/client/nouveau')}
          >
            Nouveau client
          </LongButton>
        </ButtonsContainer>
      </LargeTitle>
      <FilterDesktopContainer>
        <ClientsFilters
          groupList={groupList}
          isFetchingGroupList={isFetchingGroupList}
        />
      </FilterDesktopContainer>
      <FilterMobileContainer>
        <BoldSeparator />
        <FormTitleWithArrow onClick={() => setOpenedFilterMenu(!openedFilterMenu)}>
          Filtres
          <DropDownArrow open={openedFilterMenu} />
        </FormTitleWithArrow>
        <Collapse
          in={openedFilterMenu}
          timeout="auto"
          unmountOnExit
        >
          <ClientsFilters
            groupList={groupList}
            isFetchingGroupList={isFetchingGroupList}
          />
        </Collapse>
        <BoldSeparatorMargin />
      </FilterMobileContainer>
      <LoadingOverlay isLoading={isFetchingClientPagination}>
        <TableCardContainer>
          <TableCardContentContainer>
            <Table
              rows={clientPagination?.data ?? []}
              setRows={() => null}
              colHeaders={cols}
              defaultOrder={formikForm.initialValues}
              onRowClick={(row) => onEditClient((row).id)}
              limit={formikForm.initialValues.limit}
              sorting={{ setOrder, setOrderBy }}
              pagination={{ totalRows, setLimit, setPage }}
              resultsPerPage={[ 10, 25, 50, 100, totalRows ?? 200 ]}
            />
          </TableCardContentContainer>
        </TableCardContainer>
        <MobileCardContainer>
          <Pagination
            count={clientPagination?.meta.lastPage}
            page={formikForm.values.page}
            onChange={(e, page) => setPage(page)}
          />
          {
            clientPagination?.data?.map((client) => (
              <ClientsTableMobileCard
                key={client.id}
                client={client}
                onEdit={() => onEditClient(client.id)}
                onDelete={() => setDeleteModal(client)}
              />
            ))
          }
          <Pagination
            count={clientPagination?.meta.lastPage}
            page={formikForm.values.page}
            onChange={(e, page) => setPage(page)}
          />
        </MobileCardContainer>
      </LoadingOverlay>
      {
        deleteModal &&
          <ClientsDeleteModal
            open
            client={deleteModal}
            handleClose={() => setDeleteModal(null)}
          />
      }
    </Form>
  )
}

export default ClientsPage
