/* Framework imports -------------------------------------------------------- */
import React, { useMemo } from 'react'

/* Module imports ----------------------------------------------------------- */
import { selectTheme } from 'store/slices/themeSlice'
import { useAppSelector } from 'store/hooks'
import { isValidHexColor } from 'components/ColoredSquareChip/ColoredSquareChip'
import { useGetSettingsQuery } from 'store/api'
import {
  INFO_COLOR,
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  emotionTheme,
} from './emotionTheme'

/* Component imports -------------------------------------------------------- */
import { ThemeProvider } from '@mui/material'

/* Component declaration ---------------------------------------------------- */
interface ThemeProps {
  children: React.ReactNode;
}

const Theme: React.FC<ThemeProps> = ({ children, ...props }) => {
  const themeState = useAppSelector(selectTheme)

  const {
    currentData: settings,
    isFetching: isFetchingSettings,
  } = useGetSettingsQuery()

  const getColor = (defaultColor: string, color?: string): string => isValidHexColor(color) && color ? color : defaultColor

  const theme = useMemo(() => emotionTheme({
    mode: themeState.mode ?? 'light',
    primary: getColor(PRIMARY_COLOR, settings?.color1),
    secondary: getColor(SECONDARY_COLOR, settings?.color2),
    info: getColor(INFO_COLOR, settings?.color3),
  }), [ themeState.mode, isFetchingSettings ])

  return (
    <ThemeProvider
      theme={theme}
      {...props}
    >
      {children}
    </ThemeProvider>
  )
}

export default Theme
