/* Framework imports -------------------------------------------------------- */
import React, {
  useMemo,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  usePatchReviewCardMutation,
  usePostReviewCardReactionMutation,
} from 'store/api'
import {
  useAppDispatch,
  useAuthInfo,
} from 'store/hooks'
import { isAllowed } from 'helpers/isAllowed'
import { setReviewOpen } from 'store/slices/reviewSlice'

/* Component imports -------------------------------------------------------- */
import {
  Badge,
  Card,
  Dialog,
  IconButton,
  Tooltip,
} from '@mui/material'
import {
  Chat,
  DoneAll,
} from '@mui/icons-material'
import { Draggable } from 'react-beautiful-dnd'
import EmojiPicker, { Emoji } from 'emoji-picker-react'

/* Type imports ------------------------------------------------------------- */
import Avatar from 'components/Avatar/Avatar'
import ReviewsCardModal from './ReviewsCardModal'

/* Types declaration -------------------------------------------------------- */
import type { EmojiClickData } from 'emoji-picker-react'
import type { ReviewCard } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
interface StyledItemProps {
  read: string;
}

const StyledItem = styled(Card)<StyledItemProps>`
  min-height: 50px;
  padding: 4px;
  transition: background-color .8s ease-out;
  margin-bottom: 10px;
  background-color: ${(props) => props.read === 'true' ? props.theme.colors.grey : props.theme.colors.lightgrey};
  transition: filter .1s ease-in;

  &:last-child {
    margin-bottom: 0px;
  }

  &:hover {
    filter: brightness(0.8);
    transition: filter .1s ease-in;
  }
`

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const CardFirstRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 5px;
`

const TitleAvatarContainer = styled.div`
  display: flex;
  gap: 5px;
  align-self: center;
`

const EmojiReadContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

const EmojiGroup = styled.div`
  margin-left: auto;
  padding-right: 5px;
  padding-top: 2px;
  display: flex;
  flex-direction: row;
`

interface EmojiButtonProps {
  mine: string;
}

const EmojiButton = styled(IconButton)<EmojiButtonProps>`
  border: ${(props) => props.mine === 'true' ? `1px solid ${props.theme.palette.primary.main}` : ''};
  background-color: ${(props) => props.mine === 'true' ? `${props.theme.palette.primary.main}55` : ''};
`

const EmojiTooltip = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`

const AvatarTalkContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 13px;
  margin-right: 5px;

  .MuiBadge-badge {
    bottom: -5px;
    right: 15px;
  }
`

/* Component declaration ---------------------------------------------------- */
interface ReviewsCardProps {
  card: ReviewCard;
  index: number;
  reviewBoardUserId: number;
}

const ReviewsCard: React.FC<ReviewsCardProps> = ({ card, index, reviewBoardUserId }) => {
  const authInfo = useAuthInfo()
  const dispatch = useAppDispatch()
  const [ editCard, setEditCard ] = useState<boolean>(false)
  const [ openEmojiModal, setOpenEmojiModal ] = useState<boolean>(false)

  const [
    submitEditReviewCard,
    { isLoading: isSubmitingEditReviewCard },
  ] = usePatchReviewCardMutation()
  const [
    submitAddReactionCard,
  ] = usePostReviewCardReactionMutation()

  const onReadClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    if (isAllowed({ userId: reviewBoardUserId })) {
      await submitEditReviewCard({ id: card.id, reviewBoardId: card.reviewBoardId, data: { isRead: !card.isRead }})
    }
  }

  const onEmojiModalClose = () => {
    setOpenEmojiModal(false)
    dispatch(setReviewOpen(false))
  }

  const onEditCardOpen = () => {
    setEditCard(true)
    dispatch(setReviewOpen(true))
  }

  const onEditCardClose = () => {
    setEditCard(false)
    dispatch(setReviewOpen(false))
  }

  const onEmojiModalClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setOpenEmojiModal(true)
    dispatch(setReviewOpen(true))
  }

  const onEmojiClick = async (emoji: EmojiClickData, event: MouseEvent) => {
    event.stopPropagation()
    onEmojiModalClose()
    await submitAddReactionCard({ reviewBoardId: card.reviewBoardId, reviewCardId: card.id, data: { emoji: emoji.unified }})
  }

  const myEmoji = useMemo(() => card.reactions?.find((reaction) => reaction.userId === authInfo.id), [ card, authInfo ])

  return (
    <Draggable
      draggableId={card.id.toString()}
      index={index}
    >
      {
        (provided) => (
          <React.Fragment>
            <StyledItem
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              onClick={onEditCardOpen}
              read={String(card.isRead)}
            >
              <CardContainer>
                <CardFirstRowContainer>
                  <TitleAvatarContainer>
                    <AvatarTalkContainer>
                      <Avatar
                        size="small"
                        initials={card.user?.initials}
                        avatarColor={card.user?.settings?.avatarColor}
                      />
                      {
                        card.comments?.length || card.description ?
                          <Badge
                            badgeContent={card.comments?.length}
                            color="info"
                            anchorOrigin={
                              {
                                vertical: 'bottom',
                                horizontal: 'right',
                              }
                            }
                          >
                            <Chat color="primary" />
                          </Badge> :
                          <div />
                      }
                    </AvatarTalkContainer>
                    {card.title}
                  </TitleAvatarContainer>
                  <EmojiReadContainer>
                    <IconButton
                      size="small"
                      onClick={onReadClick}
                      disabled={isSubmitingEditReviewCard}
                    >
                      <DoneAll color={card.isRead ? 'secondary' : 'disabled'} />
                    </IconButton>
                    <EmojiButton
                      size="small"
                      onClick={onEmojiModalClick}
                      disabled={isSubmitingEditReviewCard}
                      mine={myEmoji?.emoji ? 'true' : 'false'}
                    >
                      <Emoji
                        unified={myEmoji?.emoji ?? '1f44d'}
                        size={20}
                      />
                    </EmojiButton>
                  </EmojiReadContainer>
                </CardFirstRowContainer>
                <Tooltip
                  placement="right"
                  title={
                    <div>
                      {
                        [ ...card.reactions ?? [] ].sort((a, b) => a.emoji.localeCompare(b.emoji)).map((reaction) => (
                          <EmojiTooltip key={reaction.id}>
                            <Emoji
                              unified={reaction.emoji}
                              size={22}
                            />
                            {reaction.user?.firstNameWithInitial}
                          </EmojiTooltip>
                        ))
                      }
                    </div>
                  }
                >
                  <EmojiGroup>
                    {
                      [ ...card.reactions ?? [] ].sort((a, b) => a.emoji.localeCompare(b.emoji)).map((reaction) => (
                        <Emoji
                          key={reaction.id}
                          unified={reaction.emoji}
                          size={17}
                        />
                      ))
                    }
                  </EmojiGroup>
                </Tooltip>
              </CardContainer>
            </StyledItem>
            {
              editCard &&
                <ReviewsCardModal
                  handleClose={onEditCardClose}
                  reviewCard={card}
                  reviewBoardCategoryId={card.reviewBoardCategoryId}
                  reviewBoardId={card.reviewBoardId}
                  reviewBoardUserId={reviewBoardUserId}
                />
            }
            {
              openEmojiModal &&
                <Dialog
                  open
                  onClose={onEmojiModalClose}
                  maxWidth="xs"
                >
                  <EmojiPicker onEmojiClick={onEmojiClick} />
                </Dialog>
            }
          </React.Fragment>
        )
      }
    </Draggable>
  )
}

export default ReviewsCard
