/* Framework imports -------------------------------------------------------- */
import React, {
  useMemo,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  useDeleteReviewCardCommentMutation,
  usePostReviewCardCommentReactionMutation,
} from 'store/api'
import { isAllowed } from 'helpers/isAllowed'
import { useAuthInfo } from 'store/hooks'

/* Component imports -------------------------------------------------------- */
import {
  Card,
  Dialog,
  IconButton,
  Tooltip,
} from '@mui/material'
import {
  Delete,
  Edit,
} from '@mui/icons-material'
import EmojiPicker, { Emoji } from 'emoji-picker-react'
import CustomIconButton from 'components/IconButtons/CustomIconButton/CustomIconButton'
import Avatar from 'components/Avatar/Avatar'
import ReviewsCardModalCommentAddEdit from './ReviewsCardModalCommentAddEdit'

/* Type imports ------------------------------------------------------------- */
import type { EmojiClickData } from 'emoji-picker-react'
import type { ReviewCardComment } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const CardContainer = styled(Card)`
  padding: 10px;
  background-color: ${(props) => props.theme.colors.lightgrey};
  margin-top: 10px;
`

const Container = styled.div`
  display: flex;
  gap: 10px;
`

const CommentButtonContainer = styled.div`
  margin-left: auto;
  display: flex;
`

const CommentTitle = styled.div`
  font-size: .8rem;
`

const EmojiGroup = styled.div`
  padding-left: 5px;
`

interface EmojiButtonProps {
  mine: string;
}

const EmojiButton = styled(IconButton)<EmojiButtonProps>`
  border: ${(props) => props.mine === 'true' ? `1px solid ${props.theme.palette.primary.main}` : ''};
  background-color: ${(props) => props.mine === 'true' ? `${props.theme.palette.primary.main}55` : ''};
  width: 40px;
  height: 40px;
`

const EmojiTooltip = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`

/* Component declaration ---------------------------------------------------- */
interface ReviewsCardModalCommentProps {
  comment: ReviewCardComment;
  reviewCardId: number;
  reviewBoardId: number;
  displayLastEdit: (createdAt: string, updatedAt: string) => React.ReactNode;
  allowRefresh: () => void;
}

const ReviewsCardModalComment: React.FC<ReviewsCardModalCommentProps> = ({
  reviewCardId,
  reviewBoardId,
  comment,
  displayLastEdit,
  allowRefresh,
}) => {
  const authInfo = useAuthInfo()
  const [ editComment, setEditComment ] = useState<boolean>(false)
  const [ openEmojiModal, setOpenEmojiModal ] = useState<boolean>(false)

  const [
    submitDeleteReviewCardComment,
    { isLoading: isDeletingComment },
  ] = useDeleteReviewCardCommentMutation()
  const [
    submitAddReaction,
  ] = usePostReviewCardCommentReactionMutation()

  const onDeleteCardComment = async () => {
    await submitDeleteReviewCardComment({ id: comment.id, reviewCardId, reviewBoardId: reviewBoardId })
    allowRefresh()
  }

  const onEmojiClick = async (emoji: EmojiClickData, event: MouseEvent) => {
    event.stopPropagation()
    setOpenEmojiModal(false)
    await submitAddReaction({ reviewBoardId: reviewBoardId, reviewCardCommentId: comment.id, data: { emoji: emoji.unified }})
    allowRefresh()
  }

  const onEmojiModalClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setOpenEmojiModal(true)
  }

  const handleClose = () => {
    setEditComment(false)
    allowRefresh()
  }

  const myEmoji = useMemo(() => comment.reactions?.find((reaction) => reaction.userId === authInfo.id), [ comment, authInfo ])

  if (editComment) {
    return (
      <CardContainer key={comment.id}>
        <ReviewsCardModalCommentAddEdit
          key={comment.id}
          reviewBoardId={reviewBoardId}
          reviewCardId={reviewCardId}
          reviewCardComment={comment}
          handleClose={handleClose}
        />
      </CardContainer>
    )
  }

  return (
    <CardContainer>
      <Container>
        <Avatar
          size="small"
          initials={comment.user?.initials}
          avatarColor={comment.user?.avatarColor}
        />
        <div>
          <CommentTitle>
            <b>
              {comment.user?.firstNameWithInitial}
            </b>
            {displayLastEdit(comment.createdAt, comment.updatedAt)}
          </CommentTitle>
          {comment.message}
        </div>
        <CommentButtonContainer>
          <EmojiButton
            size="small"
            onClick={onEmojiModalClick}
            mine={myEmoji?.emoji ? 'true' : 'false'}
          >
            <Emoji
              unified={myEmoji?.emoji ?? '1f44d'}
              size={20}
            />
          </EmojiButton>
          {
            isAllowed({ userId: comment.userId }) &&
              <React.Fragment>
                <CustomIconButton
                  Icon={Edit}
                  variant="outlined"
                  onClick={() => setEditComment(true)}
                  label="Modifier le commentaire"
                />
                <CustomIconButton
                  Icon={Delete}
                  variant="contained"
                  color="error"
                  onClick={onDeleteCardComment}
                  disabled={isDeletingComment}
                  label="Supprimer le commentaire"
                />
              </React.Fragment>
          }
        </CommentButtonContainer>
      </Container>
      <div>
        <Tooltip
          placement="left"
          title={
            <div>
              {
                [ ...comment.reactions ?? [] ].sort((a, b) => a.emoji.localeCompare(b.emoji)).map((reaction) => (
                  <EmojiTooltip key={reaction.id}>
                    <Emoji
                      unified={reaction.emoji}
                      size={22}
                    />
                    {reaction.user?.firstNameWithInitial}
                  </EmojiTooltip>
                ))
              }
            </div>
          }
        >
          <EmojiGroup>
            {
              [ ...comment.reactions ?? [] ].sort((a, b) => a.emoji.localeCompare(b.emoji)).map((reaction) => (
                <Emoji
                  key={reaction.id}
                  unified={reaction.emoji}
                  size={17}
                />
              ))
            }
          </EmojiGroup>
        </Tooltip>
      </div>
      {
        openEmojiModal &&
          <Dialog
            open
            onClose={() => setOpenEmojiModal(false)}
            maxWidth="xs"
          >
            <EmojiPicker onEmojiClick={onEmojiClick} />
          </Dialog>
      }
    </CardContainer>
  )
}

export default ReviewsCardModalComment
