/* eslint-disable @typescript-eslint/no-unused-vars */
/* Framework imports -------------------------------------------------------- */
import * as RTKQuery from '@reduxjs/toolkit/query/react'

/* Module imports ----------------------------------------------------------- */
import OpenAPI from 'API/OpenAPI'
import { processRequest } from 'helpers/fetchHelpers'
import { apiUrl } from 'helpers/readConfig'

/* Type imports ------------------------------------------------------------- */
import type {
  LoginValidator,
  User,
  Activity,
  ActivityStoreValidator,
  ActivityUpdateValidator,
  ActivityListParams,
  Project,
  Client,
  ActivityPaginated,
  ActivityStoreBulk,
  ProjectPaginated,
  IndexPaginatedProjectListParams,
  Software,
  ProjectStatus,
  ProjectUpdateValidator,
  ProjectStoreValidator,
  UserStoreValidator,
  UserUpdatePasswordValidator,
  IncidentPaginated,
  IncidentListParams,
  IncidentStatus,
  IncidentFamily,
  Incident,
  IncidentUpdateValidator,
  IncidentPriority,
  IncidentHistoryStoreValidator,
  IncidentHistory,
  IncidentStoreValidator,
  IncidentAction,
  UserSettings,
  UserSettingsUpdateValidator,
  Imputation,
  ImputationStoreValidator,
  ImputationUpdateValidator,
  ImputationStoreDefaultValidator,
  ClientPaginated,
  IndexPaginatedClientListParams,
  ClientStoreValidator,
  ClientUpdateValidator,
  ClientGroup,
  EnvironmentUrl,
  EnvironmentUrlPaginated,
  IndexPaginatedEnvironmentUrlListParams,
  EnvironmentUrlStoreValidator,
  EnvironmentUrlUpdateValidator,
  EnvironmentType,
  EnvironmentTypeStoreValidator,
  EnvironmentTypeUpdateValidator,
  IncidentFileStoreValidator,
  IncidentFile,
  ReviewBoard,
  ReviewCardStoreValidator,
  ReviewCard,
  ReviewCardUpdateValidator,
  ReviewBoardUpdateCardOrderValidator,
  ReviewBoardStoreValidator,
  ReviewBoardUpdateValidator,
  ReviewBoardCategoryStoreValidator,
  ReviewBoardCategory,
  ReviewBoardCategoryUpdateValidator,
  ReviewReactionStoreValidator,
  ReviewReactionUpdateValidator,
  ReviewRate,
  ReviewRateStoreValidator,
  ReviewRateUpdateValidator,
  ReviewBoardEmail,
  ReviewActivityReport,
  ReviewActivityReportStoreValidator,
  ReviewActivityReportUpdateValidator,
  ReviewActivityReportUpdateValidValidator,
  ReviewCardCommentStoreValidator,
  ReviewCardComment,
  ReviewCardCommentUpdateValidator,
  ReviewCardReaction,
  ReviewCardCommentReaction,
  UserPaginated,
  IndexPaginatedUserListParams,
  UserUpdateValidator,
  ResetPasswordValidator,
  NewPasswordValidator,
} from 'API/__generated__/Api'

/* API Redux service -------------------------------------------------------- */
export const api = RTKQuery.createApi(
  {
    reducerPath: 'api',
    baseQuery: RTKQuery.fetchBaseQuery({ baseUrl: apiUrl }) as RTKQuery.BaseQueryFn<string | RTKQuery.FetchArgs, unknown, unknown>,
    tagTypes: [
      'AUTH',
      'ACTIVITIES',
      'ENVIRONMENT_TYPES',
      'ENVIRONMENT_URLS',
      'INCIDENTS',
      'PROFILE',
      'PROJECTS',
      'CLIENTS',
      'SETTINGS',
      'USERS',
    ],
    endpoints: (build) => {
      return {
        /* Authentification */
        login: build.mutation<User, LoginValidator>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.auth.loginAuthCreate,
                args,
              )
            },
            invalidatesTags: (result, error) => error ? [] : [ 'AUTH', 'ACTIVITIES', 'PROJECTS', 'PROFILE', 'USERS' ],
          },
        ),
        resetPassword: build.mutation<void, ResetPasswordValidator>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.auth.resetPasswordAuthCreate,
                args,
              )
            },
          },
        ),
        postNewPassword: build.mutation<void, {token: string; data: NewPasswordValidator}>(
          {
            query: (args) => {
              return {
                url: `/auth/storePassword`,
                method: 'POST',
                body: args.data,
                headers: { 'Authorization': `Bearer ${args.token}` },
              }
            },
            // queryFn: async (args) => {
            //   return processRequest(
            //     OpenAPI.auth.storePasswordAuthCreate,
            //     args.data,
            //   )
            // },
          },
        ),
        /* Profile */
        getProfile: build.query<User, void>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.user.profileUserList,
              )
            },
            providesTags: (result, error, arg) => [
              { type: 'PROFILE', id: result?.id || '' },
            ],
          },
        ),
        patchPassword: build.mutation<User, UserUpdatePasswordValidator>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.user.updatePasswordUserPartialUpdate,
                args,
              )
            },
          },
        ),
        getSettings: build.query<UserSettings, void>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.settings.settingsDetail,
                0,
              )
            },
            providesTags: (result, error, arg) => [
              { type: 'SETTINGS', id: result?.id || '' },
            ],
          },
        ),
        patchSettings: build.mutation<UserSettings, UserSettingsUpdateValidator>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.settings.settingsPartialUpdate,
                0,
                args,
              )
            },
            invalidatesTags: (result, error) => error ? [] : [ 'SETTINGS', 'PROFILE' ],
          },
        ),
        /* Users */
        getUserList: build.query<User[], void>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.user.userList,
              )
            },
            providesTags: (result = [], error, arg) => [
              { type: 'USERS', id: 'LIST' },
              ...result.map((r) => ({ type: 'USERS' as const, id: `${r.id}` })),
            ],
          },
        ),
        getAllUserList: build.query<User[], void>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.user.allUserList,
              )
            },
            providesTags: (result = [], error, arg) => [
              { type: 'USERS', id: 'LIST' },
              ...result.map((r) => ({ type: 'USERS' as const, id: `${r.id}` })),
            ],
          },
        ),
        getUserPaginated: build.query<UserPaginated, IndexPaginatedUserListParams>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.user.indexPaginatedUserList,
                args,
              )
            },
            providesTags: (result, error, arg) => [
              { type: 'USERS', id: 'LIST' },
              ...result?.data.map((r) => ({ type: 'USERS' as const, id: `${r.id}` })) ?? [],
            ],
            keepUnusedDataFor: 3600,
          },
        ),
        getUser: build.query<User, number>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.user.userDetail,
                args,
              )
            },
            providesTags: (result, error, arg) => [
              { type: 'USERS', id: result?.id || '' },
            ],
          },
        ),
        postUser: build.mutation<User, UserStoreValidator>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.user.userCreate,
                args,
              )
            },
            invalidatesTags: (result, error) => error ? [] : [ 'USERS' ],
          },
        ),
        patchUser: build.mutation<User, { id: number; data: UserUpdateValidator }>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.user.userPartialUpdate,
                args.id,
                args.data,
              )
            },
            invalidatesTags: (result, error) => error ? [] : [ 'USERS' ],
          },
        ),
        patchProfile: build.mutation<User, UserUpdateValidator>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.user.updateProfileUserPartialUpdate,
                args,
              )
            },
            invalidatesTags: (result, error) => error ? [] : [ 'USERS' ],
          },
        ),
        /* Activities */
        getActivityList: build.query<ActivityPaginated, ActivityListParams>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.activity.activityList,
                args,
              )
            },
            providesTags: (result, error, arg) => [
              { type: 'ACTIVITIES', id: 'LIST' },
              ...result?.data.map((r) => ({ type: 'ACTIVITIES' as const, id: `${r.id}` })) ?? [],
            ],
            keepUnusedDataFor: 3600,
          },
        ),
        getActivity: build.query<Activity, number>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.activity.activityDetail,
                args,
              )
            },
            providesTags: (result, error, arg) => [
              { type: 'ACTIVITIES', id: result?.id || '' },
            ],
          },
        ),
        postActivity: build.mutation<Activity, ActivityStoreValidator>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.activity.activityCreate,
                args,
              )
            },
            invalidatesTags: (result, error) => error ? [] : [ 'ACTIVITIES' ],
          },
        ),
        postBulkActivities: build.mutation<void, ActivityStoreBulk>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.activity.storeBulkActivityCreate,
                args,
              )
            },
            invalidatesTags: (result, error) => error ? [] : [ 'ACTIVITIES' ],
          },
        ),
        patchActivity: build.mutation<Activity, { id: number; data: ActivityUpdateValidator }>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.activity.activityPartialUpdate,
                args.id,
                args.data,
              )
            },
            invalidatesTags: (result, error) => error ? [] : [ 'ACTIVITIES' ],
          },
        ),
        deleteActivity: build.mutation<void, number>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.activity.activityDelete,
                args,
              )
            },
            invalidatesTags: (result, error) => error ? [] : [ 'ACTIVITIES' ],
          },
        ),
        /* Projects */
        getActiveProjectList: build.query<Project[], void>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.project.indexActiveProjectList,
              )
            },
            providesTags: (result = [], error, arg) => [
              { type: 'PROJECTS', id: 'LIST' },
              ...result.map((r) => ({ type: 'PROJECTS' as const, id: `${r.id}` })),
            ],
            keepUnusedDataFor: 3600,
          },
        ),
        getProjectList: build.query<Project[], void>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.project.projectList,
              )
            },
            providesTags: (result = [], error, arg) => [
              { type: 'PROJECTS', id: 'LIST' },
              ...result.map((r) => ({ type: 'PROJECTS' as const, id: `${r.id}` })),
            ],
            keepUnusedDataFor: 3600,
          },
        ),
        getProjectPaginated: build.query<ProjectPaginated, IndexPaginatedProjectListParams>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.project.indexPaginatedProjectList,
                args,
              )
            },
            providesTags: (result, error, arg) => [
              { type: 'PROJECTS', id: 'LIST' },
              ...result?.data.map((r) => ({ type: 'PROJECTS' as const, id: `${r.id}` })) ?? [],
            ],
            keepUnusedDataFor: 3600,
          },
        ),
        getProject: build.query<Project, string>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.project.projectDetail,
                args,
              )
            },
            providesTags: (result, error, arg) => [
              { type: 'PROJECTS', id: result?.id || '' },
            ],
          },
        ),
        postProject: build.mutation<Project, ProjectStoreValidator>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.project.projectCreate,
                args,
              )
            },
            invalidatesTags: (result, error) => error ? [] : [ 'PROJECTS' ],
          },
        ),
        patchProject: build.mutation<Project, { id: string; data: ProjectUpdateValidator }>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.project.projectPartialUpdate,
                args.id,
                args.data,
              )
            },
            invalidatesTags: (result, error) => error ? [] : [ 'PROJECTS' ],
          },
        ),
        deleteProject: build.mutation<void, string>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.project.projectDelete,
                args,
              )
            },
            invalidatesTags: (result, error) => error ? [] : [ 'PROJECTS' ],
          },
        ),
        /* Imputations */
        postProjectDefaultImputations: build.mutation<void, ImputationStoreDefaultValidator>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.imputation.storeDefaultImputationCreate,
                args,
              )
            },
            invalidatesTags: (result, error) => error ? [] : [ 'PROJECTS' ],
          },
        ),
        postProjectImputation: build.mutation<Imputation, ImputationStoreValidator>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.imputation.imputationCreate,
                args,
              )
            },
          },
        ),
        patchProjectImputation: build.mutation<Imputation, { id: number; data: ImputationUpdateValidator }>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.imputation.imputationPartialUpdate,
                args.id,
                args.data,
              )
            },
          },
        ),
        deleteProjectImputation: build.mutation<void, number>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.imputation.imputationDelete,
                args,
              )
            },
          },
        ),
        /* Incidents */
        getIncidentList: build.query<IncidentPaginated, IncidentListParams>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.incident.incidentList,
                args,
              )
            },
            providesTags: (result, error, arg) => [
              { type: 'INCIDENTS', id: 'LIST' },
              ...result?.data.map((r) => ({ type: 'INCIDENTS' as const, id: `${r.id}` })) ?? [],
            ],
            keepUnusedDataFor: 3600,
          },
        ),
        getIncident: build.query<Incident, number>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.incident.incidentDetail,
                args,
              )
            },
            providesTags: (result, error, arg) => [
              { type: 'INCIDENTS', id: result?.id || '' },
            ],
            keepUnusedDataFor: 3600,
          },
        ),
        postIncident: build.mutation<Incident, { data: IncidentStoreValidator }>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.incident.incidentCreate,
                args.data,
              )
            },
            invalidatesTags: (result, error) => error ? [] : [ 'INCIDENTS' ],
          },
        ),
        patchIncident: build.mutation<Incident, { id: number; data: IncidentUpdateValidator }>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.incident.incidentPartialUpdate,
                args.id,
                args.data,
              )
            },
            invalidatesTags: (result, error) => error ? [] : [ 'INCIDENTS' ],
          },
        ),
        deleteIncident: build.mutation<void, number>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.incident.incidentDelete,
                args,
              )
            },
            invalidatesTags: (result, error) => error ? [] : [ 'INCIDENTS' ],
          },
        ),
        postIncidentFiles: build.mutation<IncidentFile, { incidentId: number; data: IncidentFileStoreValidator }>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.incident.fileIncidentCreate,
                args.incidentId,
                args.data,
              )
            },
            invalidatesTags: (result, error) => error ? [] : [ 'INCIDENTS' ],
          },
        ),
        deleteIncidentFile: build.mutation<void, { incidentId: number; id: number }>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.incident.fileIncidentDelete,
                args.incidentId,
                args.id,
              )
            },
            invalidatesTags: (result, error) => error ? [] : [ 'INCIDENTS' ],
          },
        ),
        postIncidentAction: build.mutation<IncidentHistory, { incidentId: number; data: IncidentHistoryStoreValidator }>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.incident.historyIncidentCreate,
                args.incidentId,
                args.data,
              )
            },
            invalidatesTags: (result, error) => error ? [] : [ 'INCIDENTS' ],
          },
        ),
        getIncidentFamilyList: build.query<IncidentFamily[], void>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.incidentFamily.incidentFamilyList,
              )
            },
            keepUnusedDataFor: 3600,
          },
        ),
        getSupportUserList: build.query<User[], void>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.user.supportUserList,
              )
            },
            providesTags: (result, error, arg) => [
              { type: 'USERS', id: 'LIST' },
              ...result?.map((r) => ({ type: 'USERS' as const, id: `${r.id}` })) ?? [],
            ],
            keepUnusedDataFor: 3600,
          },
        ),
        getIncidentStatusList: build.query<IncidentStatus[], void>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.incidentStatus.incidentStatusList,
              )
            },
            keepUnusedDataFor: 3600,
          },
        ),
        getIncidentPriorityList: build.query<IncidentPriority[], void>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.incidentPriority.incidentPriorityList,
              )
            },
            keepUnusedDataFor: 3600,
          },
        ),
        getIncidentActionList: build.query<IncidentAction[], void>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.incidentAction.incidentActionList,
              )
            },
            keepUnusedDataFor: 3600,
          },
        ),
        /* Clients */
        getClientList: build.query<Client[], void>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.client.clientList,
              )
            },
            providesTags: (result, error, arg) => [
              { type: 'CLIENTS', id: 'LIST' },
              ...result?.map((r) => ({ type: 'CLIENTS' as const, id: `${r.id}` })) ?? [],
            ],
            keepUnusedDataFor: 3600,
          },
        ),
        getClientPaginated: build.query<ClientPaginated, IndexPaginatedClientListParams>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.client.indexPaginatedClientList,
                args,
              )
            },
            providesTags: (result, error, arg) => [
              { type: 'CLIENTS', id: 'LIST' },
              ...result?.data.map((r) => ({ type: 'CLIENTS' as const, id: `${r.id}` })) ?? [],
            ],
            keepUnusedDataFor: 3600,
          },
        ),
        getClient: build.query<Client, string>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.client.clientDetail,
                args,
              )
            },
            providesTags: (result, error, arg) => [
              { type: 'CLIENTS', id: result?.id || '' },
            ],
          },
        ),
        postClient: build.mutation<Client, ClientStoreValidator>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.client.clientCreate,
                args,
              )
            },
            invalidatesTags: (result, error) => error ? [] : [ 'CLIENTS' ],
          },
        ),
        patchClient: build.mutation<Client, { id: string; data: ClientUpdateValidator }>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.client.clientPartialUpdate,
                args.id,
                args.data,
              )
            },
            invalidatesTags: (result, error) => error ? [] : [ 'CLIENTS' ],
          },
        ),
        deleteClient: build.mutation<void, string>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.client.clientDelete,
                args,
              )
            },
            invalidatesTags: (result, error) => error ? [] : [ 'CLIENTS' ],
          },
        ),
        /* Software */
        getSoftwareList: build.query<Software[], void>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.software.softwareList,
              )
            },
            keepUnusedDataFor: 3600,
          },
        ),
        /* Project Status */
        getProjectStatusList: build.query<ProjectStatus[], void>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.projectStatus.projectStatusList,
              )
            },
            keepUnusedDataFor: 3600,
          },
        ),
        /* Client Groups */
        getClientGroupList: build.query<ClientGroup[], void>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.clientGroup.clientGroupList,
              )
            },
            keepUnusedDataFor: 3600,
          },
        ),
        /* Environment */
        getEnvironmentUrlList: build.query<EnvironmentUrl[], void>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.environmentUrl.environmentUrlList,
              )
            },
            providesTags: (result = [], error, arg) => [
              { type: 'ENVIRONMENT_URLS', id: 'LIST' },
              ...result.map((r) => ({ type: 'ENVIRONMENT_URLS' as const, id: `${r.id}` })),
            ],
            keepUnusedDataFor: 3600,
          },
        ),
        getEnvironmentUrlPaginated: build.query<EnvironmentUrlPaginated, IndexPaginatedEnvironmentUrlListParams>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.environmentUrl.indexPaginatedEnvironmentUrlList,
                args,
              )
            },
            providesTags: (result, error, arg) => [
              { type: 'ENVIRONMENT_URLS', id: 'LIST' },
              ...result?.data.map((r) => ({ type: 'ENVIRONMENT_URLS' as const, id: `${r.id}` })) ?? [],
            ],
            keepUnusedDataFor: 3600,
          },
        ),
        getEnvironmentUrl: build.query<EnvironmentUrl, number>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.environmentUrl.environmentUrlDetail,
                args,
              )
            },
            providesTags: (result, error, arg) => [
              { type: 'ENVIRONMENT_URLS', id: result?.id || '' },
            ],
          },
        ),
        postEnvironmentUrl: build.mutation<EnvironmentUrl, EnvironmentUrlStoreValidator>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.environmentUrl.environmentUrlCreate,
                args,
              )
            },
            invalidatesTags: (result, error) => error ? [] : [ 'ENVIRONMENT_URLS' ],
          },
        ),
        patchEnvironmentUrl: build.mutation<EnvironmentUrl, { id: number; data: EnvironmentUrlUpdateValidator }>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.environmentUrl.environmentUrlPartialUpdate,
                args.id,
                args.data,
              )
            },
            invalidatesTags: (result, error) => error ? [] : [ 'ENVIRONMENT_URLS' ],
          },
        ),
        deleteEnvironmentUrl: build.mutation<void, number>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.environmentUrl.environmentUrlDelete,
                args,
              )
            },
            invalidatesTags: (result, error) => error ? [] : [ 'ENVIRONMENT_URLS' ],
          },
        ),
        /* Environment Types */
        getEnvironmentTypeList: build.query<EnvironmentType[], void>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.environmentType.environmentTypeList,
              )
            },
            providesTags: (result = [], error, arg) => [
              { type: 'ENVIRONMENT_TYPES', id: 'LIST' },
              ...result.map((r) => ({ type: 'ENVIRONMENT_TYPES' as const, id: `${r.id}` })),
            ],
            keepUnusedDataFor: 3600,
          },
        ),
        getEnvironmentType: build.query<EnvironmentType, number>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.environmentType.environmentTypeDetail,
                args,
              )
            },
            providesTags: (result, error, arg) => [
              { type: 'ENVIRONMENT_TYPES', id: result?.id || '' },
            ],
          },
        ),
        postEnvironmentType: build.mutation<EnvironmentType, EnvironmentTypeStoreValidator>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.environmentType.environmentTypeCreate,
                args,
              )
            },
            invalidatesTags: (result, error) => error ? [] : [ 'ENVIRONMENT_TYPES' ],
          },
        ),
        patchEnvironmentType: build.mutation<EnvironmentType, { id: number; data: EnvironmentTypeUpdateValidator }>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.environmentType.environmentTypePartialUpdate,
                args.id,
                args.data,
              )
            },
            invalidatesTags: (result, error) => error ? [] : [ 'ENVIRONMENT_TYPES' ],
          },
        ),
        deleteEnvironmentType: build.mutation<void, number>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.environmentType.environmentTypeDelete,
                args,
              )
            },
            invalidatesTags: (result, error) => error ? [] : [ 'ENVIRONMENT_TYPES' ],
          },
        ),
        /* Review Boards */
        getReviewBoardList: build.query<ReviewBoard[], void>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.reviewBoard.reviewBoardList,
              )
            },
          },
        ),
        getReviewBoard: build.query<ReviewBoard, number>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.reviewBoard.reviewBoardDetail,
                args,
              )
            },
          },
        ),
        getReviewBoardEmail: build.query<ReviewBoardEmail, number>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.reviewBoard.emailReviewBoardList,
                args,
              )
            },
          },
        ),
        postReviewBoard: build.mutation<ReviewBoard, ReviewBoardStoreValidator>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.reviewBoard.reviewBoardCreate,
                args,
              )
            },
          },
        ),
        patchReviewBoard: build.mutation<ReviewBoard, { id: number; data: ReviewBoardUpdateValidator }>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.reviewBoard.reviewBoardPartialUpdate,
                args.id,
                args.data,
              )
            },
          },
        ),
        deleteReviewBoard: build.mutation<void, number>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.reviewBoard.reviewBoardDelete,
                args,
              )
            },
          },
        ),
        patchReviewCardOrder: build.mutation<ReviewCard, { id: number; data: ReviewBoardUpdateCardOrderValidator }>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.reviewBoard.updateCardOrderReviewBoardPartialUpdate,
                args.id,
                args.data,
              )
            },
          },
        ),
        /* Review Board Categories */
        postReviewBoardCategory: build.mutation<ReviewBoardCategory, { reviewBoardId: number; data: ReviewBoardCategoryStoreValidator }>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.reviewBoard.reviewBoardCategoryReviewBoardCreate,
                args.reviewBoardId,
                args.data,
              )
            },
          },
        ),
        patchReviewBoardCategory: build.mutation<ReviewBoardCategory, { reviewBoardId: number; id: number; data: ReviewBoardCategoryUpdateValidator }>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.reviewBoard.reviewBoardCategoryReviewBoardPartialUpdate,
                args.reviewBoardId,
                args.id,
                args.data,
              )
            },
          },
        ),
        deleteReviewBoardCategory: build.mutation<void, { reviewBoardId: number; id: number }>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.reviewBoard.reviewBoardCategoryReviewBoardDelete,
                args.reviewBoardId,
                args.id,
              )
            },
          },
        ),
        /* Review Rates */
        postReviewRate: build.mutation<ReviewRate, { reviewBoardId: number; data: ReviewRateStoreValidator }>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.reviewBoard.reviewRateReviewBoardCreate,
                args.reviewBoardId,
                args.data,
              )
            },
          },
        ),
        patchReviewRate: build.mutation<ReviewRate, { reviewBoardId: number; id: number; data: ReviewRateUpdateValidator }>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.reviewBoard.reviewRateReviewBoardPartialUpdate,
                args.reviewBoardId,
                args.id,
                args.data,
              )
            },
          },
        ),
        /* Review Cards */
        postReviewCard: build.mutation<ReviewCard, { reviewBoardId: number; data: ReviewCardStoreValidator }>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.reviewBoard.reviewCardReviewBoardCreate,
                args.reviewBoardId,
                args.data,
              )
            },
          },
        ),
        patchReviewCard: build.mutation<ReviewCard, { reviewBoardId: number; id: number; data: ReviewCardUpdateValidator }>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.reviewBoard.reviewCardReviewBoardPartialUpdate,
                args.reviewBoardId,
                args.id,
                args.data,
              )
            },
          },
        ),
        deleteReviewCard: build.mutation<void, { reviewBoardId: number; id: number }>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.reviewBoard.reviewCardReviewBoardDelete,
                args.reviewBoardId,
                args.id,
              )
            },
          },
        ),
        /* Review Card Comment */
        postReviewCardComment: build.mutation<ReviewCardComment, { reviewBoardId: number; reviewCardId: number; data: ReviewCardCommentStoreValidator }>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.reviewBoard.reviewCardCommentReviewCardCommentCreate,
                args.reviewBoardId,
                args.reviewCardId,
                args.data,
              )
            },
          },
        ),
        patchReviewCardComment: build.mutation<ReviewCardComment, { reviewBoardId: number; reviewCardId: number; id: number; data: ReviewCardCommentUpdateValidator }>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.reviewBoard.reviewCardCommentReviewCardCommentPartialUpdate,
                args.reviewBoardId,
                args.reviewCardId,
                args.id,
                args.data,
              )
            },
          },
        ),
        deleteReviewCardComment: build.mutation<void, { reviewBoardId: number; reviewCardId: number; id: number }>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.reviewBoard.reviewCardCommentReviewCardCommentDelete,
                args.reviewBoardId,
                args.reviewCardId,
                args.id,
              )
            },
          },
        ),
        /* Review Reactions */
        postReviewCardReaction: build.mutation<ReviewCardReaction, { reviewBoardId: number; reviewCardId: number; data: ReviewReactionStoreValidator }>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.reviewBoard.reviewCardReactionReviewCardCreate,
                args.reviewBoardId,
                args.reviewCardId,
                args.data,
              )
            },
          },
        ),
        patchReviewCardReaction: build.mutation<ReviewCardReaction, { reviewBoardId: number; reviewCardId: number; id: number; data: ReviewReactionUpdateValidator }>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.reviewBoard.reviewCardReactionReviewCardPartialUpdate,
                args.reviewBoardId,
                args.reviewCardId,
                args.id,
                args.data,
              )
            },
          },
        ),
        postReviewCardCommentReaction: build.mutation<ReviewCardCommentReaction, { reviewBoardId: number; reviewCardCommentId: number; data: ReviewReactionStoreValidator }>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.reviewBoard.reviewCardCommentReactionReviewCardCommentCreate,
                args.reviewBoardId,
                args.reviewCardCommentId,
                args.data,
              )
            },
          },
        ),
        patchReviewCardCommentReaction: build.mutation<ReviewCardCommentReaction, { reviewBoardId: number; reviewCardCommentId: number; id: number; data: ReviewReactionUpdateValidator }>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.reviewBoard.reviewCardCommentReactionReviewCardCommentPartialUpdate,
                args.reviewBoardId,
                args.reviewCardCommentId,
                args.id,
                args.data,
              )
            },
          },
        ),
        /* Review Activity Reports */
        postReviewActivityReport: build.mutation<ReviewActivityReport, { reviewBoardId: number; data: ReviewActivityReportStoreValidator }>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.reviewBoard.reviewActivityReportReviewBoardCreate,
                args.reviewBoardId,
                args.data,
              )
            },
          },
        ),
        patchReviewActivityReport: build.mutation<ReviewActivityReport, { reviewBoardId: number; id: number; data: ReviewActivityReportUpdateValidator }>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.reviewBoard.reviewActivityReportReviewBoardPartialUpdate,
                args.reviewBoardId,
                args.id,
                args.data,
              )
            },
          },
        ),
        deleteReviewActivityReport: build.mutation<void, { reviewBoardId: number; id: number }>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.reviewBoard.reviewActivityReportReviewBoardDelete,
                args.reviewBoardId,
                args.id,
              )
            },
          },
        ),
        postReviewActivityReportSend: build.mutation<ReviewActivityReport, { reviewBoardId: number }>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.reviewBoard.sendReviewActivityReportCreate,
                args.reviewBoardId,
                null,
              )
            },
          },
        ),
        patchReviewActivityReportValidate: build.mutation<ReviewActivityReport, { reviewBoardId: number; id: number; data: ReviewActivityReportUpdateValidValidator }>(
          {
            queryFn: async (args) => {
              return processRequest(
                OpenAPI.reviewBoard.validateReviewActivityReportPartialUpdate,
                args.reviewBoardId,
                args.id,
                args.data,
              )
            },
          },
        ),
      }
    },
  },
)

export const {
  // Auth
  useLoginMutation,
  useResetPasswordMutation,
  usePostNewPasswordMutation,
  // Profile
  useGetProfileQuery,
  useLazyGetProfileQuery,
  useGetSettingsQuery,
  useLazyGetSettingsQuery,
  usePatchPasswordMutation,
  usePatchSettingsMutation,
  // Users
  useGetUserListQuery,
  useLazyGetUserListQuery,
  useGetAllUserListQuery,
  useLazyGetAllUserListQuery,
  useGetUserPaginatedQuery,
  useLazyGetUserPaginatedQuery,
  useGetUserQuery,
  useLazyGetUserQuery,
  usePostUserMutation,
  usePatchUserMutation,
  usePatchProfileMutation,
  // Activities
  useGetActivityListQuery,
  useLazyGetActivityListQuery,
  useGetActivityQuery,
  useLazyGetActivityQuery,
  usePostActivityMutation,
  usePostBulkActivitiesMutation,
  usePatchActivityMutation,
  useDeleteActivityMutation,
  // Projects
  useGetActiveProjectListQuery,
  useLazyGetActiveProjectListQuery,
  useGetProjectListQuery,
  useLazyGetProjectListQuery,
  useGetProjectPaginatedQuery,
  useLazyGetProjectPaginatedQuery,
  useGetProjectQuery,
  useLazyGetProjectQuery,
  usePostProjectMutation,
  usePatchProjectMutation,
  useDeleteProjectMutation,
  // Imputations
  usePostProjectDefaultImputationsMutation,
  usePostProjectImputationMutation,
  usePatchProjectImputationMutation,
  useDeleteProjectImputationMutation,
  // Incidents
  useGetIncidentListQuery,
  useLazyGetIncidentListQuery,
  useGetIncidentQuery,
  useLazyGetIncidentQuery,
  useGetSupportUserListQuery,
  useLazyGetSupportUserListQuery,
  useGetIncidentFamilyListQuery,
  useLazyGetIncidentFamilyListQuery,
  useGetIncidentStatusListQuery,
  useLazyGetIncidentStatusListQuery,
  useGetIncidentPriorityListQuery,
  useLazyGetIncidentPriorityListQuery,
  useGetIncidentActionListQuery,
  useLazyGetIncidentActionListQuery,
  usePostIncidentMutation,
  usePatchIncidentMutation,
  useDeleteIncidentMutation,
  usePostIncidentFilesMutation,
  useDeleteIncidentFileMutation,
  usePostIncidentActionMutation,
  // Clients
  useGetClientListQuery,
  useLazyGetClientListQuery,
  useGetClientPaginatedQuery,
  useLazyGetClientPaginatedQuery,
  useGetClientQuery,
  useLazyGetClientQuery,
  usePostClientMutation,
  usePatchClientMutation,
  useDeleteClientMutation,
  // Software
  useGetSoftwareListQuery,
  useLazyGetSoftwareListQuery,
  // Project Status
  useGetProjectStatusListQuery,
  useLazyGetProjectStatusListQuery,
  // Client Groups
  useGetClientGroupListQuery,
  useLazyGetClientGroupListQuery,
  // Environment
  useGetEnvironmentUrlListQuery,
  useLazyGetEnvironmentUrlListQuery,
  useGetEnvironmentUrlPaginatedQuery,
  useLazyGetEnvironmentUrlPaginatedQuery,
  useGetEnvironmentUrlQuery,
  useLazyGetEnvironmentUrlQuery,
  usePostEnvironmentUrlMutation,
  usePatchEnvironmentUrlMutation,
  useDeleteEnvironmentUrlMutation,
  // Environment Types
  useGetEnvironmentTypeListQuery,
  useLazyGetEnvironmentTypeListQuery,
  useGetEnvironmentTypeQuery,
  useLazyGetEnvironmentTypeQuery,
  usePostEnvironmentTypeMutation,
  usePatchEnvironmentTypeMutation,
  useDeleteEnvironmentTypeMutation,
  // Review Boards
  useGetReviewBoardListQuery,
  useLazyGetReviewBoardListQuery,
  useGetReviewBoardQuery,
  useLazyGetReviewBoardQuery,
  useGetReviewBoardEmailQuery,
  useLazyGetReviewBoardEmailQuery,
  usePostReviewBoardMutation,
  usePatchReviewBoardMutation,
  useDeleteReviewBoardMutation,
  usePatchReviewCardOrderMutation,
  // Review Board Categories
  usePostReviewBoardCategoryMutation,
  usePatchReviewBoardCategoryMutation,
  useDeleteReviewBoardCategoryMutation,
  // Review Rates
  usePostReviewRateMutation,
  usePatchReviewRateMutation,
  // Review Cards
  usePostReviewCardMutation,
  usePatchReviewCardMutation,
  useDeleteReviewCardMutation,
  // Review Card Comments
  usePostReviewCardCommentMutation,
  usePatchReviewCardCommentMutation,
  useDeleteReviewCardCommentMutation,
  // Review Reactions
  usePostReviewCardReactionMutation,
  usePatchReviewCardReactionMutation,
  usePostReviewCardCommentReactionMutation,
  usePatchReviewCardCommentReactionMutation,
  // Review Activity Reports
  usePostReviewActivityReportMutation,
  usePatchReviewActivityReportMutation,
  useDeleteReviewActivityReportMutation,
  usePostReviewActivityReportSendMutation,
  usePatchReviewActivityReportValidateMutation,
} = api
