/* Framework imports -------------------------------------------------------- */
import React from 'react'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  useNavigate,
  useParams, 
} from 'react-router-dom'
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import { usePostNewPasswordMutation } from 'store/api'
import { isApiError } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import { Button } from '@mui/material'
import { toast } from 'react-toastify'
import AuthContainer from 'components/AuthComponents/AuthContainer'
import Loader from 'components/Loader/Loader'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import AuthWelcomeMessage from 'components/AuthComponents/AuthWelcomeMessage'
import AuthInputContainer from 'components/AuthComponents/AuthInputContainer'
import AuthButtonsContainer from 'components/AuthComponents/AuthButtonsContainer'
import FormikPasswordInput from 'components/AuthComponents/FormikPasswordInput'

/* Type imports ------------------------------------------------------------- */
import type { FormikHelpers } from 'formik'
import type { NewPasswordValidator } from 'API/__generated__/Api'

/* Types declaration -------------------------------------------------------- */
const NewPasswordFormSchema = Yup.object().shape({
  password: Yup.string().required('Le mot de passe est invalide'),
})

/* Component declaration ---------------------------------------------------- */
interface NewPasswordPageProps {}

const NewPasswordPage: React.FC<NewPasswordPageProps> = () => {
  const navigate = useNavigate()
  const { token = '' } = useParams<{ token: string }>()

  const [
    newPassword,
  ] = usePostNewPasswordMutation()

  const onSubmit = (values: NewPasswordValidator, { setSubmitting }: FormikHelpers<NewPasswordValidator> ): void | Promise<void> => {
    newPassword({ data: values, token }).then((response) => {
      setSubmitting(false)
      if (isApiError(response)) {
        toast.error(`Une erreur est survenue.`)
      } else {
        toast.success(`Votre mot de passe à bien été modifié ! Vous pouvez maintenant vous connecter.`)
        navigate('/connexion')
      }
    }).catch(console.error)
  }

  const formikForm = useForm<NewPasswordValidator>(
    {
      initialValues: {
        password: '',
      },
      onSubmit: onSubmit,
      validationSchema: NewPasswordFormSchema,
    },
  )

  const onCancelClick = () => {
    navigate('/connexion')
  }

  return (
    <AuthContainer>
      <Form form={formikForm}>
        {formikForm.isSubmitting && <Loader />}
        <AuthWelcomeMessage>
          Nouveau mot de passe
        </AuthWelcomeMessage>
        <div>
          <AuthInputContainer>
            <FormBoldTitle>
              Mot de passe
            </FormBoldTitle>
            <FormikPasswordInput
              name="password"
              autoComplete="current-password"
            />
          </AuthInputContainer>
        </div>
        <AuthButtonsContainer>
          <Button
            type="submit"
            disabled={formikForm.isSubmitting}
            variant="contained"
            fullWidth
          >
            Valider
          </Button>
          <Button
            variant="outlined"
            fullWidth
            disabled={formikForm.isSubmitting}
            onClick={onCancelClick}
          >
            Annuler
          </Button>
        </AuthButtonsContainer>
      </Form>
    </AuthContainer>
  )
}

export default NewPasswordPage
