/* Framework imports -------------------------------------------------------- */
import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  useGetUserQuery,
  usePatchUserMutation,
} from 'store/api'
import { isApiError } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { toast } from 'react-toastify'
import CloseButton from 'components/CloseButton/CloseButton'
import LoadingOverlay from 'components/Loader/LoadingOverlay'
import UsersModalItem from './UsersModalItem'

/* Type imports ------------------------------------------------------------- */
import type { FormikContextType } from 'formik'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import type { UserUpdateValidator } from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
const userBulkSchema = Yup.object().shape<Shape<UserUpdateValidator>>({
  email: Yup.string().email("L'email est invalide"),
  isDisabled: Yup.boolean(),
  isSupport: Yup.boolean(),
}).required()

type UserRequest = FormikContextType<UserUpdateValidator>

/* Styled components -------------------------------------------------------- */
const DialogTitleContainer = styled(DialogTitle)`
  font-weight: bold;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 1.5rem;
  margin-top: 20px;
  text-transform: uppercase;
  text-align: center;
`

const DialogActionContainer = styled(DialogActions)`
  display: flex;
  justify-content: center;
  margin: 0px 0px 15px;
  padding: 0px;
`

const FormButton = styled(Button)`
  margin: 10px 5px 0px;
  min-width: 150px;

  @media ${(props) => props.theme.media.mobile.main} {
    min-width: 110px;
  }
`

/* Component declaration ---------------------------------------------------- */
interface UsersEditModalProps {
  id: number;
  open: boolean;
  handleClose: () => void;
}

const UsersEditModal: React.FC<UsersEditModalProps> = ({
  open,
  handleClose,
  id,
  ...props
}) => {
  const {
    currentData: user,
    isFetching: isFetchingUser,
  } = useGetUserQuery(id)
  const [
    submitEditUserInfo,
  ] = usePatchUserMutation()

  const formikForm: UserRequest = useForm<UserUpdateValidator>(
    {
      initialValues: {
        email: '',
        isDisabled: false,
      },
      validationSchema: userBulkSchema,
    },
  )

  useEffect(() => {
    if (!isFetchingUser && user) {
      formikForm.setValues({ ...user })
    }
  }, [ isFetchingUser ])

  const onSubmit = (): void => {
    formikForm.submitForm().catch(console.error)
    formikForm.validateForm()
      .then((errors) => {
        if (Object.keys(errors).length === 0) return submitEditUserInfo({ id, data: formikForm.values })
      })
      .then((response) => {
        if (!response) return
        else if (isApiError(response)) {
          toast.error('Une erreur est survenue lors de la modification du collaborateur.')
          formikForm.setSubmitting(false)
        }
        else {
          formikForm.resetForm()
          handleClose()
        }
      })
      .catch(console.error)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth
    >
      <Form form={formikForm}>
        <DialogTitleContainer>
          {`Modifier ${user?.fullName}`}
          <CloseButton handleClose={handleClose} />
        </DialogTitleContainer>
        <DialogContent>
          <LoadingOverlay isLoading={isFetchingUser}>
            <UsersModalItem
              isDisabled={formikForm.values.isDisabled || false}
              isSupport={formikForm.values.isSupport || false}
              setDisabled={(value) => formikForm.setFieldValue('isDisabled', value)}
              setIsSupport={(value) => formikForm.setFieldValue('isSupport', value)}
              {...props}
            />
          </LoadingOverlay>
        </DialogContent>
        <DialogActionContainer>
          <FormButton
            onClick={handleClose}
            variant="outlined"
          >
            Annuler
          </FormButton>
          <FormButton
            variant="contained"
            onClick={onSubmit}
            disabled={formikForm.isSubmitting}
          >
            {formikForm.isSubmitting ? <CircularProgress size={24} /> : 'Valider'}
          </FormButton>
        </DialogActionContainer>
      </Form>
    </Dialog>
  )
}

export default UsersEditModal
