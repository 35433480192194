/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import DateUtils from 'helpers/DateUtils'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Card,
} from '@mui/material'

/* Type imports ------------------------------------------------------------- */
import type { Activity } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const CardContainer = styled(Card)`
  margin: 10px 0px;
`

const CardContentContainer = styled.div`
  padding: 10px;
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5px;
  align-items: center;
  font-weight: bold;
  margin-bottom: 5px;
`

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
`

/* Component declaration ---------------------------------------------------- */
interface ActivitiesTableMobileCardProps {
  activity: Activity;
  onDelete: () => void;
  onEdit: () => void;
}

const ActivitiesTableMobileCard: React.FC<ActivitiesTableMobileCardProps> = ({
  activity,
  onDelete,
  onEdit,
}) => {

  return (
    <CardContainer>
      <CardContentContainer>
        <TitleContainer>
          {DateUtils.APIStrToLocalDateString(activity.date)}
        </TitleContainer>
        {`Acteur : ${activity.user?.firstNameWithInitial || ''}`}
        <br />
        {`Client : ${activity.client?.name || ''}`}
        <br />
        {`Projet : ${activity.project?.name || ''}`}
        <br />
        {`Imputation : ${activity.imputation?.name || ''}`}
        <br />
        {`Commentaire : ${activity.comment || ''}`}
        <br />
        <ButtonContainer>
          <Button
            variant="contained"
            color="error"
            onClick={onDelete}
          >
            Supprimer
          </Button>
          <Button
            variant="contained"
            onClick={onEdit}
          >
            Modifier
          </Button>
        </ButtonContainer>
      </CardContentContainer>
    </CardContainer>
  )
}

export default ActivitiesTableMobileCard
