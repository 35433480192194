/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  useLazyGetReviewBoardQuery,
  usePatchReviewCardOrderMutation,
  usePatchReviewBoardMutation,
  usePostReviewBoardCategoryMutation,
} from 'store/api'
import { isAllowed } from 'helpers/isAllowed'
import {
  useAppSelector,
  useAuthInfo,
} from 'store/hooks'
import { selectReview } from 'store/slices/reviewSlice'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  InputAdornment,
  Rating,
  TextField,
  Tooltip,
} from '@mui/material'
import {
  Delete,
  Edit,
} from '@mui/icons-material'
import { DragDropContext } from 'react-beautiful-dnd'
import CustomIconButton from 'components/IconButtons/CustomIconButton/CustomIconButton'
import ReviewsCategory from './ReviewsCategory'
import ReviewsRateModal from './ReviewsRateModal'
import ReviewsEmailModal from './ReviewsEmailModal'
import ReviewsBoardDeleteModal from './ReviewsBoardDeleteModal'
import ReviewsReportManagement from './ReviewsReportManagement'
import ReviewsBoardChangeAdminModal from './ReviewsBoardChangeAdminModal'

/* Type imports ------------------------------------------------------------- */
import type { DropResult } from 'react-beautiful-dnd'
import type { Socket } from 'socket.io-client'
import type { ReviewBoardCategory } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
interface StyledColumnsProps {
  length: number;
}

const StyledColumns = styled.div<StyledColumnsProps>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.length}, 1fr);
  width: 100%;
  gap: 10px;
`

const Container = styled.div`
  width: 100%;
`

interface TitleTextFieldProps {
  editing: string;
  color?: string;
}

const TitleTextField = styled(TextField)<TitleTextFieldProps>`
  .MuiInputBase-root {
    font-size: 1.1rem;
    background-color: ${(props) => props.editing === 'true' ? props.theme.colors.main : 'transparent'};
    border: ${(props) => props.editing === 'true' ? `1px solid ${props.theme.palette.primary.main}` : 'none'};
    color: ${(props) => props.color === 'info' ? props.theme.palette.info.main : props.theme.palette.secondary.main};
    font-weight: bold;
  }

  fieldset {
    border: ${(props) => props.editing === 'true' ? `1px solid ${props.theme.palette.primary.main}` : 'none'};
  }
`

const TitleContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 10px;
  align-items: center;
`

const RateContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

const RateTooltip = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`

const ButtonContainer = styled.div`
  display: flex;
  gap: 5px;
`

const WordFieldContainer = styled.div`
  display: flex;
  gap: 5px;
`

/* Component declaration ---------------------------------------------------- */
interface ReviewsBoardProps {
  boardId: number;
  socket: Socket;
}

const ReviewsBoard: React.FC<ReviewsBoardProps> = ({ boardId, socket }) => {
  const authInfo = useAuthInfo()
  const reviewState = useAppSelector(selectReview)
  const [ categories, setCategories ] = useState<ReviewBoardCategory[]>([])
  const [ savedCategories, setSavedCategories ] = useState<ReviewBoardCategory[]>([])
  const [ editTitle, setEditTitle ] = useState<boolean>(false)
  const [ newTitle, setNewTitle ] = useState<string>('')
  const [ editMagicWord, setEditMagicWord ] = useState<boolean>(false)
  const [ newMagicWord, setMagicWord ] = useState<string>('')
  const [ addRate, setAddRate ] = useState<boolean>(false)
  const [ openEmail, setOpenEmail ] = useState<boolean>(false)
  const [ openDeleteBoard, setOpenDeleteBoard ] = useState<boolean>(false)
  const [ openEditAdmin, setOpenEditAdmin ] = useState<boolean>(false)

  const [
    submitPostNewCategory,
    { isLoading: isSubmittingNewCategory },
  ] = usePostReviewBoardCategoryMutation()
  const [
    submitPatchBoard,
    { isLoading: isSubmittingPatchBoard },
  ] = usePatchReviewBoardMutation()
  const [
    submitPatchCardOrder,
  ] = usePatchReviewCardOrderMutation()
  const [
    fetchBoard,
    { currentData: board },
  ] = useLazyGetReviewBoardQuery()

  const refetchBoard = async () => {
    if (!reviewState.open) {
      await fetchBoard(boardId).then((value) => {
        setCategories(value.data?.boardCategories ?? [])
      }).catch(console.error)
    }
  }

  useEffect(() => {
    refetchBoard()
  }, [ boardId ])

  useEffect(() => {
    if (!reviewState.open && savedCategories.length && savedCategories[0]?.reviewBoardId === boardId) {
      setCategories(savedCategories)
    }
  }, [ savedCategories, reviewState.open ])

  useEffect(() => {
    socket.on(`boards:${boardId}`, () => refetchBoard())
    socket.on(`boards:${boardId}:reports`, () => refetchBoard())
    socket.on(`boards:${boardId}:cards`, ({ categories }: {categories: ReviewBoardCategory[]}) => setSavedCategories(categories))

    return () => {
      socket.off(`boards:${boardId}`)
      socket.off(`boards:${boardId}:reports`)
      socket.off(`boards:${boardId}:cards`)
    }
  }, [ boardId, socket ])

  const onDragEnd = ({ source, destination }: DropResult) => {
    if (!destination) return null

    if (source.droppableId === destination.droppableId && destination.index === source.index) {
      return null
    }

    const start = categories.find((category) => category.id?.toString() === source.droppableId)
    const end = categories.find((category) => category.id?.toString() === destination.droppableId)

    if (!start || !end || !start.cards || !end.cards) return null

    if (start.reviewCategoryId === end.reviewCategoryId) {
      const newList = structuredClone(start.cards.filter((_, idx: number) => idx !== source.index) ?? [])

      newList.splice(destination.index, 0, start.cards[source.index])
      setCategories((state) => [ ...state.map((cat) => cat.id === start.id ? ({ ...cat, cards: newList }) : cat) ])

      submitPatchCardOrder({
        id: boardId,
        data: { cards: newList.map((item, index) => ({ ...item, index })) },
      })
      return null
    } else {
      const newStartList = structuredClone(start.cards.filter((_, idx: number) => idx !== source.index) ?? [])
      const newEndList = structuredClone(end.cards)

      newEndList.splice(destination.index, 0, { ...start.cards[source.index], reviewBoardCategoryId: end.id })

      setCategories((state) => [ ...state.map((cat) =>
        cat.id === start.id ?
          ({ ...cat, cards: newStartList }) :
          cat.id === end.id ? ({ ...cat, cards: newEndList }) : cat) ])

      submitPatchCardOrder({
        id: boardId,
        data: { cards: [ ...newStartList.map((item, index) => ({ ...item, index })), ...newEndList.map((item, index) => ({ ...item, index })) ]},
      })
      return null
    }
  }

  const onEditTitle = async () => {
    if (!newTitle || !isAllowed({ userId: board?.userId })) return

    await submitPatchBoard({ id: boardId, data: { title: newTitle }}).then(() => setEditTitle(false))
  }

  const onEditMagicWord = async () => {
    if (!newMagicWord || !isAllowed({ userId: board?.userId })) return

    await submitPatchBoard({ id: boardId, data: { magicWord: newMagicWord }}).then(() => setEditMagicWord(false))
  }

  const onNewCategoryClick = async () => {
    await submitPostNewCategory({ reviewBoardId: boardId, data: { name: 'Nouvelle colonne' }})
  }

  const onDeleteBoard = () => {
    setOpenDeleteBoard(true)
  }

  const onClickEditTitle = () => {
    if (board && isAllowed({ userId: board.userId })) {
      setEditTitle(true)
      setNewTitle(board.title)
    }
  }

  const onClickEditMagicWord = () => {
    if (board && isAllowed({ userId: board.userId })) {
      setEditMagicWord(true)
      setMagicWord(board.magicWord)
    }
  }

  const onChangeAdmin = () => {
    setOpenEditAdmin(true)
  }

  const onValidateReview = async () => {
    await submitPatchBoard({ id: boardId, data: { displayReports: !board?.displayReports }})
  }

  if (!board) return null

  return (
    <Container>
      <TitleContainer>
        {
          board.rates?.find((rate) => rate.userId === authInfo.id) ?
            <Tooltip
              placement="right"
              title={
                <div>
                  {
                    board.rates?.map((rate) => (
                      <RateTooltip key={rate.id}>
                        <Rating
                          value={rate.rate}
                          precision={0.1}
                          readOnly
                        />
                        {rate.user?.firstNameWithInitial}
                      </RateTooltip>
                    ))
                  }
                </div>
              }
            >
              <RateContainer>
                <CustomIconButton
                  Icon={Edit}
                  variant="outlined"
                  onClick={() => setAddRate(true)}
                  label="Changer la note de ma semaine"
                />
                <Rating
                  value={board.rate || 0}
                  precision={0.1}
                  readOnly
                />
                {`${board.rate || 0}/5`}
              </RateContainer>
            </Tooltip> :
            <Button
              variant="contained"
              onClick={() => setAddRate(true)}
            >
              Noter ma semaine
            </Button>
        }
        {
          board ?
            <WordFieldContainer>
              <TitleTextField
                editing={String(editTitle)}
                value={editTitle ? newTitle : board.title}
                onChange={(e) => isAllowed({ userId: board.userId }) && setNewTitle(e.target.value)}
                placeholder="Titre de la review"
                size="small"
                onBlur={() => setEditTitle(false)}
                onClick={onClickEditTitle}
                onKeyDown={(e) => e.key === 'Enter' ? onEditTitle() : e.key === 'Escape' ? setEditTitle(false) : null}
              />
              <TitleTextField
                color="info"
                editing={String(editMagicWord)}
                value={editMagicWord ? newMagicWord : board.magicWord}
                onChange={(e) => isAllowed({ userId: board.userId }) && setMagicWord(e.target.value)}
                size="small"
                placeholder="Mot magique"
                onBlur={() => setEditMagicWord(false)}
                onClick={onClickEditMagicWord}
                onKeyDown={(e) => e.key === 'Enter' ? onEditMagicWord() : e.key === 'Escape' ? setEditMagicWord(false) : null}
                InputProps={
                  {
                    startAdornment:
                    (
                      <InputAdornment position="start">
                        🪄
                      </InputAdornment>
                    ),
                  }
                }
              />
            </WordFieldContainer> :
            <div />
        }
        <ButtonContainer>
          <Button
            variant="contained"
            onClick={() => setOpenEmail(true)}
          >
            Générer le compte-rendu
          </Button>
          {
            isAllowed({ userId: board.userId }) &&
              <React.Fragment>
                <Button
                  variant="outlined"
                  onClick={onNewCategoryClick}
                  disabled={isSubmittingNewCategory}
                >
                  Ajouter une colonne
                </Button>
                <Button
                  variant="contained"
                  onClick={onValidateReview}
                  disabled={isSubmittingPatchBoard}
                >
                  {board.displayReports ? `In-valider la review` : 'Valider la review'}
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={onChangeAdmin}
                  disabled={isSubmittingPatchBoard}
                >
                  Changer l'admin
                </Button>
                <CustomIconButton
                  Icon={Delete}
                  variant="contained"
                  color="error"
                  onClick={onDeleteBoard}
                  label="Supprimer la review"
                />
              </React.Fragment>
          }
        </ButtonContainer>
      </TitleContainer>
      <DragDropContext onDragEnd={onDragEnd}>
        <StyledColumns length={categories.length}>
          {
            categories.map((category) => (
              <ReviewsCategory
                key={category.id}
                category={category}
                userId={board.userId || 0}
              />
            ))
          }
        </StyledColumns>
      </DragDropContext>
      <ReviewsReportManagement
        board={board}
        socket={socket}
      />
      {
        addRate &&
          <ReviewsRateModal
            handleClose={() => setAddRate(false)}
            reviewBoardId={boardId}
            rate={board.rate || 0}
          />
      }
      {
        openEmail &&
          <ReviewsEmailModal
            handleClose={() => setOpenEmail(false)}
            reviewBoardId={boardId}
          />
      }
      {
        openDeleteBoard &&
          <ReviewsBoardDeleteModal
            board={board}
            handleClose={() => setOpenDeleteBoard(false)}
          />
      }
      {
        openEditAdmin &&
          <ReviewsBoardChangeAdminModal
            boardId={board.id}
            userId={board.userId}
            handleClose={() => setOpenEditAdmin(false)}
          />
      }
    </Container>
  )
}

export default ReviewsBoard
