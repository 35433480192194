/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import SegmentedButtons from 'components/SegmentedButtons/SegmentedButtons'

/* Type imports ------------------------------------------------------------- */
import type { SegmentedButtonOption } from 'components/SegmentedButtons/SegmentedButtons'

/* Styled components -------------------------------------------------------- */
const UserContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 10px;
  align-items: flex-start;

  @media ${(props) => props.theme.media.tablet} {
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 0px;
  }

  @media ${(props) => props.theme.media.mobile.main} {
    flex-direction: column;
    gap: 0px;
    margin-bottom: 0px;
    margin-top: -10px;
  }
`

const Container = styled.div`
  width: 100%;

  @media ${(props) => props.theme.media.tablet} {
    width: calc(50% - 5px);
  }
`

/* Component declaration ---------------------------------------------------- */
interface UsersModalItemProps {
  isDisabled: boolean;
  setDisabled: ((value: boolean) => void) | null;
  isSupport: boolean;
  setIsSupport: ((value: boolean) => void) | null;
}

const UsersModalItem: React.FC<UsersModalItemProps> = ({
  isDisabled,
  setDisabled,
  isSupport,
  setIsSupport,
}) => {
  const disableOptions: SegmentedButtonOption<boolean>[] = [ { value: false, label: 'Actif' }, { value: true, label: 'Inactif' } ]
  const isSupportOptions: SegmentedButtonOption<boolean>[] = [ { value: true, label: 'Oui' }, { value: false, label: 'Non' } ]

  return (
    <UserContainer>
      <RowContainer>
        <Container>
          <FormBoldTitle
            smaller
            required
          >
            Email
          </FormBoldTitle>
          <Field
            component={TextField}
            placeholder="Email"
            name="email"
            size="small"
          />
        </Container>
      </RowContainer>
      <RowContainer>
        <Container>
          <FormBoldTitle
            smaller
            required
          >
            Prénom
          </FormBoldTitle>
          <Field
            component={TextField}
            placeholder="Prénom"
            name="firstName"
            size="small"
          />
        </Container>
        <Container>
          <FormBoldTitle
            smaller
            required
          >
            Nom
          </FormBoldTitle>
          <Field
            component={TextField}
            placeholder="Nom"
            name="lastName"
            size="small"
          />
        </Container>
      </RowContainer>
      <RowContainer>
        {
          setIsSupport &&
            <Container>
              <FormBoldTitle
                smaller
                required
              >
                Membre du support
              </FormBoldTitle>
              <SegmentedButtons
                options={isSupportOptions}
                selectedOption={isSupport}
                setSelectedOption={setIsSupport}
                smaller
              />
            </Container>
        }
        {
          setDisabled &&
            <Container>
              <FormBoldTitle
                smaller
                required
              >
                Statut
              </FormBoldTitle>
              <SegmentedButtons
                options={disableOptions}
                selectedOption={isDisabled}
                setSelectedOption={setDisabled}
                smaller
              />
            </Container>
        }
      </RowContainer>
    </UserContainer>
  )
}

export default UsersModalItem
